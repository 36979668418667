.topicList {
    padding: 1.813rem 0 1.688rem;
    position: relative;
    z-index: 1;

    @media (min-width: 768px) {
        overflow: hidden;

    }

    #dropdown-basic {
        @media (max-width: 767px) {
            max-width: 18rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    .container {
        z-index: 2;
        position: relative;

        @media (max-width: 767px) {
            padding-left: 0;
        }
    }

    .topic-list-container {
        position: relative;


        ul {
            &.scroll-data {
                margin-left: 2rem !important;
                scroll-behavior: smooth;
            }
        }

        .list-scroll {
            position: absolute;
            right: -1rem;
            top: 0.2rem;
            background-color: #fff;
            width: 1.3rem;
            height: 1.3rem;
            background: url('../../images/warningClose.svg') no-repeat center/100%;
            border: none;
            transform: rotate(-90deg);

            @media (max-width: 768px) {
                display: none;
            }

            &.left {
                left: 0.5rem;
                transform: rotate(90deg);
            }
        }

    }

    // &::after {
    //     content: "";
    //     width: 30%;
    //     height: 100%;
    //     display: inline-block;
    //     background: url("../../images/hero.svg") no-repeat 100%/100%;
    //     position: absolute;
    //     top: 0;
    //     right: 1rem;
    //     display: none;

    //     @media (min-width: 768px) and (max-width: 991px) {
    //         display: block;
    //         right: -6rem;
    //     }

    //     @media (min-width: 992px) {
    //         display: block;
    //         right: 1rem;
    //     }
    // }

    ul {
        margin-bottom: 0;
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }


        li {

            @media (max-width: 995px) {
                margin-right: 1.5rem !important;
            }

            a {
                white-space: nowrap;
            }

            a.active {
                border-bottom: 0.25rem solid $green;
                font-weight: 700;
            }
        }
    }

    #dropdown-container {
        h4 {
            color: $white;
        }

        button {
            color: $white !important;


            &::after {
                width: 0.8rem;
                height: 0.5rem;
                background: url("../../images/warningClose.svg") no-repeat center/cover;
                transform: translateY(-50%) rotate(0);
            }
        }
    }
}