.tooltip {


    .tooltip-inner {
        max-width: 70%;
        background-color: #fff;
        color: #000;
        padding: 1rem;
        border: 0.125rem solid $green;
        opacity: 1;
        text-align: left;

        @media (min-width: 768px) and (max-width: 992px) {
            max-width: 50%;
        }

        @media (min-width: 992px) {
            max-width: 25%;
        }
    }
}