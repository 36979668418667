.topics {

    .topic-title {
        margin-bottom: 1.5rem;
    }

    .container {
        // @media (min-width: 768px) {
        //     background-color: #fff;
        // }
    }

    .topic-blog-container {
        background-color: $lightBg !important;
        padding-top: 1.3rem;

        @media (min-width: 768px) {
            padding-top: 2rem !important;
        }

        .container {

            &:first-of-type {
                padding-bottom: 1.1rem;
            }

            @media (max-width: 767px) {
                width: 100%;
                max-width: 100% !important;
                margin-left: 0;
                margin-right: 0;
                padding-left: 0;
                padding-right: 0;

                &>* {
                    margin: 0 auto;
                }

                h2 {
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                    margin-bottom: 0.75rem;
                }

                #dropdown-container {
                    width: 100%;
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;

                }

                .blog {
                    &>div {
                        padding: 1.5rem;
                    }
                }
            }
        }

        @media (min-width: 768px) {
            background-color: $white !important;
        }


        #dropdown-container {
            &>div {

                @media (max-width: 767px) {
                    display: block !important;
                }

                h4 {
                    display: inline;
                }
            }
        }

        .dropdown {
            @media (max-width: 767px) {

                display: inline;
            }
        }
    }

    #dropdown-container {

        h4 {
            font-weight: $font-regular;
            line-height: 1.8rem;
        }

        &>.dropdown:not(.mobile-dropdown .dropdown) {
            position: absolute !important;
            left: 38%;
            bottom: 0;

            @media (min-width: 768px) {
                position: static !important;
                transform: translateX(0);
            }
        }

    }

    .blog {
        padding: 1rem 0;
        width: 100%;

        @media (min-width: 768px) {
            width: 90%;
            padding: 2.3rem 0;

            &:first-of-type {
                padding-top: 1.5rem;
            }

            &:last-of-type {
                padding: 2.3rem 0 0;
            }
        }

        &:not(:last-of-type) {
            border-bottom: 0.25rem solid $green;
        }

        h3 {
            font-size: 1.8rem !important;
        }

        p {
            margin-bottom: 0.6;
        }

        &>div {
            padding: 1.5rem 1.5rem;
            border: none;

            @media (min-width: 768px) {
                border: 0.125rem solid $borderLight;

                border-radius: 0.313rem;

            }


            div {
                ul {
                    list-style-type: disc;
                    padding-left: 1.5rem !important;

                }

                ol {
                    list-style-type: decimal;
                    padding-left: 1.5rem !important;
                }
            }
        }
    }

    .case-list-container {
        justify-content: space-between;

        li {
            flex-basis: 100%;

            @media (min-width: 768px) {
                flex-basis: 49.1% !important;
            }
        }

        .cards {
            margin-right: 0 !important;
        }

        h4 {
            color: $_darkGrey !important;
        }
    }

    .faq-title {
        margin-bottom: 2.7rem !important;
    }

    .homelessness {
        p {
            width: 100%;
        }

        ul {
            width: 100%;
            font-family: "inter";
        }
    }

    .case-card {

        padding: 2rem 0 0.5rem;

        @media (min-width: 768px) {
            padding: 2.95rem 0 2.75rem;

        }

        @media (max-width: 767px) {
            padding-top: 0% !important;
        }

        p {
            margin-bottom: 0;
        }
    }

    .topic-faq {
        padding: 2.1rem 0 2.2rem !important;

        @media (min-width: 768px) {
            padding: 2.5rem 0 4rem !important;
        }
    }

    .topic-show-btn {
        color: $heroBlue;
        text-decoration: underline;
        padding: 0;
    }
}