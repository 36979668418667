.modal {

    &.emergency {
        .modal-body {
            padding-top: 0;
        }
    }

    .modal-header {
        border-bottom: none;
    }
   
    .modal-body {
        padding: 2rem 2.75rem 1.625rem;
        h4 {
            font-size: 1.625rem;
            line-height: 1.869rem;
            font-weight: 700;
            font-family: "Helvetica";
            margin-bottom: 1.313rem;

            img {
                width: 2.447rem;
                height: 2.866rem;
                display: inline-block;
                margin-right: 0.563rem;
            }
        }

        p {

            font-size: 1.313rem;
            line-height: 1.875rem;
            font-weight: 400;
            font-family: "Helvetica";
        }
    }

}