.radio-container {
    &:not(:last-of-type) {
        margin-bottom: 1rem;
    }

    .form-check {
        padding-left: 0;
        
    }

    input {
        width: 1px;
        height: 1px;
        opacity: 0;
    }

    input[type=radio]:checked + label::before {
        background: url("../../images/form-radio-active.svg") no-repeat center/100%;
    }

    label {
        font-size: 1.188rem;
        line-height: 1.5rem;
        font-family: 'Helvetica';
        font-weight: 400;
        position: relative;
        padding-left: 3rem;
        margin-bottom: 1rem;

        @media (min-width: 767px) and (max-width: 992px) {
            font-size: 1rem;
        }

        

        &::before {
            content: "";
            width: 2rem;
            height: 2rem;
            display: inline-block;
            margin-right: 1rem;
            background: url("../../images/form-radio-inactive.svg") no-repeat center/100%;
            vertical-align: middle;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);

        }

     
    }
}
