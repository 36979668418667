.error-container {
    padding: 0.938rem 3rem;
    position: relative;
    border-radius: 0.313rem;
    margin-top: 1.813rem;

    &::before {
        content: "";
        width: 1.063rem;
        height: 1.063rem;
        display: inline-block;
        background: url("../../images/svg/information.svg") no-repeat center/100%;
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);

        @media (max-width: 767px) {
            top: 1.7rem;
        }

    }
    
     p {
        margin-bottom: 0;
        
        a {
            border-bottom: 0.125rem solid $white;
        }
     }
}