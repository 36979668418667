.information{
    // border-color: $blueLight;
    // border-width: 0.063rem;
    // border-radius: 0.313rem;
    border: 0.063rem solid $blueLight;
    border-radius: 0.313rem;
    background-color: $blueLight !important;
    overflow: hidden;

    .accordion-item {
        border: none;
    }

    button {
            border-bottom: none !important;
            box-shadow: none !important;
            border-color: $blueLight;
            border-bottom-width: 0.063rem !important;
            background-color: $blueLight !important;
            color: $white !important;
            padding: 0;
            position: relative;
            font-size: 1.125rem;
            line-height: 1.5rem;
            font-weight: 700;
            font-family: "Helvetica";

            &::after {
                content: "";
                display: inline-block;
                width: 1rem;
                height: 1rem;
                border: none;
                background: url("../../images/down-arrow-white.svg") no-repeat center/100% !important;
            }

    }

    @media screen and (min-width: 768px){
        background-color: $white !important;
    }
        h3{
            border-bottom: none !important;
            border-color: $blueLight;
            border-bottom-width: 0.063rem !important;
            background-color: $blueLight !important;
            padding: 0.75rem 1.25rem 0.75rem 3rem !important;
            color: $white !important;
            position: relative;
            &::before{
                content: "";
                width: 1rem;
                height: 1rem;
                background: url('../../images/svg/information.svg') no-repeat center/cover;
                display: inline-block;
                position: absolute;
                top: 50%;
                left: 1rem;
                transform: translateY(-50%);
                
            }
        }

        .accordion-body {
            margin-bottom: 0;
            background-color: $blueLight;
            color: $white;
        
            @media (min-width: 768px) {
                background: $white;
                color: $black;
            }
            

            
        }
        
    
    .description{
        font-size: 1rem;
        font-weight: 400;
        font-family: 'Helvetica';
        line-height: 1.5rem;
        padding: 0.15rem 1.5rem 0.5rem 1.25rem !important;
        margin-right: 1.25rem !important;
        background-color: $blueLight !important;
        color: $white !important;
        margin-bottom: 0;

        @media screen and (min-width: 768px){
            font-size: 1.063rem;
            line-height: 1.5rem;
            background-color: $white !important;
            color: $darkGrey !important;
        }
    }

    .accordion-body {
        margin-top: 0;
        border-top: 0.063rem $blueLight !important;
    }
    
 }