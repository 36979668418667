.video-container {

    margin-bottom: 2rem;



    #video-text {
        margin-bottom: 0;
        font-family: "Helvetica";
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        margin-bottom: 1.5rem !important;


        @media (min-width: 768px) {
            font-size: 1.125rem;
            line-height: 1.294rem;
        }

        
        &::before {
            content: "";
            display: inline-block;
            width: 2rem;
            height: 2rem;
            background: url("../../images/video.svg") no-repeat center/100%;
            vertical-align: middle;
            margin-right: 0.75rem;
        }
    }

    iframe {
        width: 100%;
    }

    .video {
        width: 100%;
        
    }
}