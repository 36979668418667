.notice-card {

    @media (max-width: 767px) {
        // width: 9%;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        margin-top: 1.5rem;
    }

    .description {
        padding: 1rem 1.5rem;
    }


    .question-title {
        border-left: 0.313rem solid $green;
        border-bottom: 0.125rem solid $lightBg;

        h2 {
            margin-bottom: 0;
        }
    }
}