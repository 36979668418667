
.start {

    & > div:first-of-type {

        @media (min-width: 768px) {
            flex-basis: 71% !important;
        }

    }

    & > div:nth-child(2) {
        @media (min-width: 768px) {
            flex-basis: 27% !important;
            
        }
    }

    .start-description {

        padding: 1.688rem 0 0;
        border-radius: 0 0 0.313rem 0.313rem;

        @media (min-width: 768px) {
           padding: 1.688rem;
        }   

        p {
            margin-bottom: 0;
            line-height: 1.5rem !important;
        }

        h3 {
            margin: 2rem 0 1.5rem;
        }

        ul {
            list-style-type: disc;
            margin-left: 1rem;
        }

        .button {
            margin-top: 1rem;
        }

        .description-bottom {
            @media (min-width: 768px) {
                margin: 2rem 0;
            }
        }

        .postcode {
            width: 23.813rem;
            padding: 0.813rem 1.375rem;
            margin-top: 1.5rem;
            font-family: "Helvetica";
            font-size: 1.125rem;
            line-height: 1.294rem;
            border-radius: 0.125rem;
            border: 0.063rem solid $darkGrey;
            color: $darkGrey;

        }
    }

    .error-container {
        p {
            line-height: 1.5rem !important;
        }
    }

    .start-button {
        margin-top: 2.5rem !important;

        @media (min-width: 768px) {
        margin-top: 0 !important;

        }
    }

    .content-wrapper {
        & > .bg-white {
             border-radius: 0 0 0.625rem 0.625rem;
        }
    }
}

.start-topics {

    padding-bottom: 3rem;

    @media (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;
    }
    

    .card-container {
        width: 100%;
        margin-top: 2rem !important;
        
        @media (min-width: 768px) {
            width: 69%;

        }

        // .cards {
        //     border-left: 0.375rem solid $heroBlue !important;
        // }

        ul {
            flex-wrap: wrap;
            @media (max-width: 767px) {
                flex-wrap: wrap;
                justify-content: space-between !important;
            }
        }

        li {
            flex-basis: 20%;
            padding: 0;

            @media (max-width: 767px) {
                flex-basis: 47% !important;
            }

            a {
                padding: 1rem;
                display: block;
                color: $lightGrey;
                font-size: 1.25rem;
                line-height: 1.625rem;
            }
        }
    }

   
}