@import "variables";

.telephoneSupportPage {
    .telePhoneContainer {
        @media screen and (min-width: 768px) {
            margin: 1.75rem 1.5rem 2.125rem 2.375rem !important;
        }


        .container {
            padding: 1rem 1.156rem;
            width: 100% !important;
            max-width: 100% !important;

            @media (min-width: 768px) {
                margin: 1.75rem auto !important;
                padding: 2rem 1.938rem;
                border-radius: 0.313rem;
            }

            .descp-text {
                // @media screen and (min-width: 768px) {
                //   margin-right: 20% !important;
                // }
            }

            h3:not(.firstQuestionSet h3) {
                border-bottom: 0.313rem solid $green;

                @media (min-width: 768px) {
                    border-bottom: 0;
                }

            }

            .title-26 {
                margin-top: 2rem !important;
                padding-bottom: 0.5rem;
            }

            .labelQuestion {
                margin-right: 2.188rem;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                flex: 1;
                position: relative;

                @media (min-width: 768px) {
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;

                }




            }

            .field-content-container {
                margin-top: 2rem;

                h3 {
                    margin-bottom: 0.5rem;
                }

                &:first-of-type {
                    .date-question {
                        @media (min-width: 768px) {
                            padding-left: 8rem !important;
                        }
                    }
                }

                .date-question {

                    input {
                        padding-top: 1.8rem;

                        @media (min-width: 768px) {
                            padding-top: 0.8rem;

                        }
                    }

                    @media (min-width: 768px) {
                        padding-left: 13rem;
                    }

                    .date {
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 1;
                        transform: translateY(0.3rem);

                        @media (min-width: 768px) {
                            top: 50%;

                            transform: translateY(-50%);

                        }
                    }

                }

            }

            .firstQuestionSet {
                margin-top: 2.25rem;

                .ck-editor-container {
                    h3 {

                        margin: 2rem 0 0.2rem !important;

                        @media (max-width: 767px) {
                            font-size: 1.2rem !important;
                        }
                    }
                }
            }

            .secondQuestionSet {
                margin-top: 2.5rem;

                .date-question-container {

                    .date-question {
                        input {
                            padding-top: 1.8rem;

                            @media (min-width: 768px) {
                                padding-top: 0.8rem;

                            }
                        }

                        @media (min-width: 768px) {
                            padding-left: 16rem;
                        }
                    }
                }

            }

            .thirdQuestionSet {
                margin-top: 2.5rem;
            }

            .text-15 {
                border: none !important
            }

            .inputComponent {
                // margin-top: 1.5rem !important;

                @media (max-width: 767px) {
                    flex-direction: column !important;
                    justify-content: flex-start !important;
                    align-items: flex-start !important;
                }
            }

            .text-15::placeholder {
                color: $lightestGery !important;
            }

            .text-span {
                font-size: 1rem;
                font-weight: 600;
                font-family: 'Helvetica';
                line-height: 1.5rem;
                color: $green;

                @media (min-width: 767px) {
                    font-size: 1rem;
                    line-height: 1.21rem;
                }
            }

            // .information {
            //     // border-color: $blueLight;
            //     // border-width: 0.063rem;
            //     // border-radius: 0.313rem;
            //     border: 0.063rem solid $blueLight;
            //     border-radius: 0.313rem;
            //     background-color: $blueLight !important;
            //     overflow: hidden;
            //     margin-top: 1.5rem;

            //     @media screen and (min-width: 768px) {
            //         background-color: $white !important;
            //     }

            //     h3.information-title {
            //         border-bottom: none !important;
            //         border-color: $blueLight;
            //         border-bottom-width: 0.063rem !important;
            //         background-color: $blueLight !important;
            //         padding: 0.75rem 1.25rem 0.75rem 3rem !important;
            //         color: $white !important;
            //         position: relative;

            //         &::before {
            //             content: "";
            //             width: 1rem;
            //             height: 1rem;
            //             background: url('../../images/svg/information.svg') no-repeat center/cover;
            //             display: inline-block;
            //             position: absolute;
            //             top: 50%;
            //             left: 1rem;
            //             transform: translateY(-50%);

            //         }
            //     }


            //     .description {
            //         font-size: 1rem;
            //         font-weight: 400;
            //         font-family: 'Helvetica';
            //         line-height: 1.5rem;
            //         padding: 0.15rem 1.5rem 0.25rem 1.25rem !important;
            //         margin-right: 1.25rem !important;
            //         background-color: $blueLight !important;
            //         color: $white !important;

            //         @media screen and (min-width: 768px) {
            //             font-size: 1.063rem;
            //             line-height: 1.5rem;
            //             background-color: $white !important;
            //             color: $darkGrey !important;
            //         }
            //     }
            // }

            .buttonContainer {
                margin: 2rem 0rem;
                height: 3.688rem;

                .discardButton {
                    text-align: center;
                    background-color: $white;
                    padding: 0rem 2.3rem !important;
                    color: $fadeBlack;
                    border: 0.125rem solid $greyLight !important;
                    border-radius: 0 !important;

                    @media (max-width: 767px) {
                        padding: 0rem 1rem !important;
                    }
                }

                .generateButton {
                    text-align: center;
                    background-color: $green !important;
                    padding: 0rem 1.625rem !important;
                    border: none !important;
                    color: 'white';
                    font-weight: 700;
                    color: $fadeBlack;

                    @media (max-width: 767px) {
                        padding: 0 1rem !important;

                    }

                    img {

                        @media (max-width: 767px) {
                            width: 2rem !important;
                            margin-left: 1rem !important;

                        }
                    }
                }
            }

            .checkBoxContainer {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0.625rem 2.063rem 0.625rem 1.063rem;
                background-color: $offWhite !important;
                color: $darkGrey;
                margin-top: 1rem;
                justify-content: space-between;
                cursor: pointer; // Make the div clickable
                position: relative;

                &.selected {
                    &::after {
                        content: "";
                        width: 100%;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        display: block;
                        position: absolute;
                        background-color: rgba(255, 255, 255, 0.5);

                    }
                }

                .checkbox-title {
                    min-width: unset;

                    @media (min-width: 768px) and (max-width: 992px) {
                        min-width: 12.5rem;
                        max-width: 12.5rem;

                    }

                    @media (min-width: 992px) {
                        min-width: 22.5rem;
                        max-width: 22.5rem;
                    }
                }


            }

            .slctStyle {
                color: $jet;
            }


            .descpStyle {

                margin: 0;
                word-break: break-word;

                @media (min-width: 768px) {
                    flex-grow: 1;
                    margin: 0 2rem;
                }

                div span strong {
                    background-color: $blueLight !important;
                }

                p {
                    margin-bottom: 0;
                }

            }

            .ck.ck-editor {
                margin-top: 1.5rem !important;

            }

        }
    }

    input[type='date'] {
        font-weight: 400;
        letter-spacing: 0.1rem;
        display: inline-block;
        color: $black !important;
        text-align: left !important;


        @media (max-width: 767px) {
            width: 17rem !important;
            min-height: 3rem !important;
            display: block !important;
            text-align: left !important;
        }
    }

    ::placeholder {
        font-weight: 400;
    }

    .ck.ck-editor {
        margin-top: 1.5rem !important;
        border: 0.094rem solid #000 !important;
    }

    .react-datepicker__input-container {
        input {
            border: 0;
            padding: 0.8rem;

            @media (max-width: 767px) {
                padding: 0.5rem;
                padding-left: 0;
            }
        }

        ::placeholder {
            color: $lightestGery;
        }
    }

    .error-container {
        p {
            font-weight: 700;
        }
    }

}