.top-tip {
    border: 0.188rem solid $green;
    border-radius: 0.313rem;
    padding: 1.313rem;
    margin-top: 1.5rem;


    h3 {
        font-size: 1.188rem;
        font-family: 'Helvetica';
        line-height: 1.366rem;
        font-weight: 700;
        color: $black;
        border-bottom: 0 !important;
    }

    ul {
        list-style-type: disc;
        padding-left: 1rem !important;

        p {
            font-size: 1.063rem;
            line-height: 1.5rem;

            margin-bottom: 0.5rem;
            font-family: 'Helvetica';
            font-weight: 400;
            color: $black;

            @media (min-width: 768px) {
                line-height: 1.063rem;
            }
        }
    }
}