.question-title {

    font-size: 1.625rem;
    line-height: 1.969rem;
    padding: 1.375rem 1.438rem ;
    margin-top: 0.5rem;
    border-left: 0.313rem solid $green;
    border-bottom: 0.125rem solid $lightBg;

    @media (max-width: 767px) {
        &:not(.notice-card .question-title) {
            border-bottom: 0.313rem solid $green;
            border-left: none;
            padding: 1rem 0 0; 
        }
    }

    
    // border-radius: 0.313rem;

    h2 {
        font-size: 1.688rem;
        font-family: 'Helvetica';
        font-weight: 700;
        line-height: 1.969rem;
        margin-top: 0.375rem;

         @media (min-width: 768px)  and (max-width: 992px) {
            font-size: 1.2rem;
        }

    }
    
}