.question-error {

    margin-top: 2rem;

    @media (min-width: 767px) {
        padding: 0 1.688rem 1rem;
        margin-top: 0rem;

    }

    .accordion {
        .accordion-item {
            border-radius: 0.188rem;
            overflow: hidden;
        }
       
    }

    button {
        background-color: $red !important;
        color: $white !important;
        font-size: 1.125rem;
        line-height: 1.5rem;
        font-weight: 700;
        font-family: "helvetica";
        box-shadow: none !important;
        outline: none;

        &::after {
            width: 0.75rem;
            height: 0.813rem;
            background: url("../../images/warningClose.svg") no-repeat center/100% !important;
            vertical-align: bottom;
        }

        &:not(.collapsed)::after {
            background  : url("../../images/warningOpen.svg") no-repeat center/cover !important;
        }
       
    }

   



    .accordion-body {
        border: 0.125rem solid $red;
        background-color: $red;
        color: $white;
        padding-top: 0;

        @media (min-width: 768px) {
            background-color: $white !important;
            color: $darkGrey;
            padding-top: 1rem;
        }

        ul {
            list-style-type: disc;
            padding-left: 1rem !important;
        }

    }
}