.errorModal {
    .modal-body {
        padding: 1rem 1rem 1.25rem !important;
        p{
            font-size: 1rem !important;
            line-height: 1.5rem !important;
            font-weight: 400 !important;
            font-family: 'inter' !important;
            color : $grey !important;
            @media (min-width: 768px) {
                font-size: 1.063rem !important;
                line-height: 1.286rem !important;
            }
        }
        .btnContainer{
            padding-top: 1.688rem !important;
        }
        .btn{
            color: $darkGrey !important;

            &:hover {
                background-color: $green;
                color: $darkGrey !important;
            }
        }
        .errorBtn{
            background-color: $white !important;
           padding: 1.188rem 2rem !important;
            border: 0.125rem solid  $greyLight !important;
            color: $_darkGrey !important;
            border-radius: 0rem !important;
            font-size: 1.125rem !important;
            line-height: 1.294rem !important;}
            @media (min-width: 768px) {
                font-size: 1.125rem !important;
                line-height: 1.294rem !important;
            }
    }

    .warning-modal {
        .btnContainer {
            button:first-of-type {
                @media (max-width: 768px) {
                    width: 53%;
                }
            }
        }
    }

    .modalErrorTitle {
        font-weight: 700;
    }
}