.contact-content {

    .hero {
        @media (max-width: 767px) {
            background: url("../../images/hero.svg") no-repeat 100% 100%/46% 18% #001D9C;
        }

        @media (min-width: 768px) and (max-width: 992px) {
            background: url("../../images/hero.svg") no-repeat 120% 100%/44% 40% #001B9C;
        }
        
        .title {
            margin-bottom: 0.5rem;
        }
    }

    .contact {

        .contactPrt1 {
            @media (min-width: 992px) {
                border: 0rem solid #00DD97;
                border-left-width: 0.313rem !important;
                border-color: $green !important;
            }
        }
        .contactPrt2 {
            @media (min-width: 992px) {
                border: 0rem solid #00DD97;
                border-left-width: 0.313rem !important;
                border-color: $green !important;
            }
        }
    
        & > div {

            padding-left: 0;


            @media (min-width: 768px) {
                padding-left: 0.813rem;
            }
    
            &.contactPrt1 {
                .section1 {
    
                    @media (min-width: 992px) {
                        margin-bottom: 2.688rem;
                    }
    
                }
            }
    
            .section {
    
                border-left: 0.313rem solid #00DD97 !important;
                padding: 1rem 2rem;
                margin-bottom: 1rem;
    
                @media (min-width: 992px) {
                    margin-bottom: 0;
                    border-left: 0 !important;
                    padding: 0;
                }

                p:first-of-type {
                    font-weight: 700;
                }
            }
        }
    
        .email {
            margin-top: 0;
            margin-bottom: 0.688rem !important;
            &::before {
                content: "";
                display: inline-block;
                width: 1.641rem;
                height: 1.641rem;
                background: url("../../images/email.svg") no-repeat center/100%;
                vertical-align: middle;
                margin-right: 0.5rem;
            }
        }
    
        .phone {
            @extend .email;
            &::before {
                background: url("../../images/phone.svg") no-repeat center/100%;
            }
        }
    
        .post {

            @extend .email;
            &::before {
                background: url("../../images/post.svg") no-repeat center/100%;
            }
        }
    
        .contactPrt2 {
    
            width: 85%;
            margin-left: 0;
    
            @media (min-width: 992px) {
                width: 19%;
                margin-left: 5%;
            }   
            
        }
    }

    .homelessness {


        li {
        flex-basis: 100% !important;

            @media (min-width: 768px) {
                flex-basis: 25% !important;
            }
        }
    }
}
   