@import "variables";

.policyPage {

    h3.title {
        margin-bottom: 1.5rem;
        @media (min-width: 768px) {
            font-size: 2rem;

        }
    }

   

    .hero {
        @media (min-width: 768px) {
            margin-top: 3.5em;
        }

        .content-container {
            h3 {
                width: 100%;
                @media (min-width: 992px) {
                    width: 39%;
                }

                @media (min-width: 768px) and (max-width: 992px) {
                    width: 63%;
                }
            }

            a.button {
                // padding: 1rem 1.188rem;
                &::after {
                    margin-left: 2.313rem;
                }
            }
        }
    }

    @media screen and (min-width: 768px) {
    }   
    .contentPage{
    width: 100%;
    padding: 1.188rem 1.063rem !important;

        @media screen and (min-width: 992px) {
            width: 70%;
            padding: 1.938rem 5rem !important;

        }


        @media screen and (min-width: 768px) and (max-width: 992px) {
            width: 90%;
            padding: 1.938rem 5rem !important;

        }
    }
    .text-24 {
        color: $heroBlue !important;
        padding-left: 0.063rem !important;
    }
    .text-18{
        line-height: 1.625rem !important;
        padding-top: 1rem !important;
        @media screen and (min-width: 768px) {
            line-height: 1.625rem !important;
        }
    }

    h3.text-tittle-18  {
        padding-top: 0 !important;
        margin-bottom: 1rem !important;
    }

    p.text-18 {
        padding-top: 0 !important;
        margin-bottom: 1rem !important;
    }

    .text-tittle-18{
        padding-top: 1rem !important;
    }

    ul {
        list-style-type: disc;
        margin-left: 1rem;
        li{
            margin-left: 0.63rem !important;

            &:first-of-type {
                padding-top: 0 !important;
            }
        }
    }

    .hero {
        min-height: unset !important;

        h3 {
            margin-top: 0;
        }


        @media (min-width: 992px) {
            background: url("../../images/hero.svg") no-repeat 100% 100%/20% 80% $heroBlue;
        }

        @media (min-width: 768px) and (max-width: 992px) {
            background: url("../../images/hero.svg") no-repeat 100% 100%/35% 74%  $heroBlue;
        }
    }

}