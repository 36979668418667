.about {

    .hero {

        background: none;
        background-color: $heroBlue;

        @media (min-width: 768px) {
            background: url('../../images/green-quarter.svg') no-repeat;
            background-position: right bottom;
            background-color: $heroBlue;
        }
        

        // &::after {
        //     @media (min-width: 768px) and (max-width: 991px) {
        //         background: url("../../images/hero.svg") no-repeat 100% 68% / 74% !important;
        //     }
        // }

        .description {
            &:last-of-type {
                margin-top: 2rem;
            }
        }


       
    }


    .about-content {

        padding: 2.063rem 0 2.5rem;
        position: relative;
        background: none;

    
       @media (min-width: 768px ) and (max-width: 995px) {
        background: url('../../images/grey-quarter.svg') no-repeat;
        background-position: right top;
        

       }

       @media (min-width: 992px) {
        background: url('../../images/grey-quarter.svg') no-repeat;
        background-position: right top;
        
       }
    
        img {
            width: 10.813rem;
            margin-bottom: 1.151rem;
        }
    
        p {
            line-height: 2.063rem;
            &:first-of-type  {
                margin-bottom: 1.5rem !important;
            }

            &:last-of-type {
                margin-bottom: 0 !important;
            }
        }
    
        ul {
            list-style-type: disc;
            margin-left: 1rem;
            margin-bottom: 2rem;
    
            li {
                p {
                margin-bottom: 0;
    
                }   
            }
        }
    }

    .homelessness .title{
        margin-bottom: 0 !important;
    }
}

