.loader {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: url('../../images/backgroundblur.png') no-repeat center/cover;
    height: calc(100vh - 358px);

    @media (min-width: 768px) {
        height: calc(100vh - 170px);
    }

    @media (min-width: 1400px) {
        height: calc(100vh - 190px);
    }

    img {
        width: 10.875rem;
        height: 10.875rem;
        z-index: 1;
    }

    h2 {
        font-size: 1.5rem;
        color: $darkBlue;
    }
}