.common-problems {

    padding: 3.438rem 0 3.5rem !important;

    @media (max-width: 767px) {
        padding: 2.438rem 0 2.9rem !important;
    }

    .title {
        margin-bottom: 0.75rem;
    }

    .container {
        & >  p {
            margin-bottom: 2.188rem !important;
        }
    } 

    @media (min-width: 768px) and (max-width: 992px) {
        .card-container {
            li {
                flex-basis: 30% !important;

                &:not(:nth-child(3n)) {
                    margin-right: 1.5rem;
                }

                &:nth-child(3n) {
                    margin-right: 0;
                }
            }
        }
    }

    #dropdown-container {
        h4 {
            font-weight: 700 !important;
        }
    }
    
}