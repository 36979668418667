footer {
    background-color: $white;
    padding: 1.625rem 0;
    box-shadow: 0.063rem 0.063rem 3rem $white;

    .container {
        @media (max-width: 767px) {
            width: 100%;
            max-width: 100% !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }



    }

    .logo {
        width: 6.125rem;

        @media (max-width: 767px) {
            order: 1;
            width: 8.25rem;
            margin-bottom: 1.875rem;
        }
    }

    .description {
        font-size: 0.938rem;
        line-height: 1.063rem;
        font-weight: $font-regular;
        color: $grey;
        margin-bottom: 0;
        flex-grow: 1;
        margin-left: 4rem;

        @media (max-width: 767px) {
            width: 100%;
            text-align: center;
            order: 3;
            margin-top: 1.875rem;
            padding-top: 1.875rem;
            border-top: 0.063rem solid #C2BFC7;
            margin-left: 0rem;
        }
    }

    .footer-list {

        margin-bottom: 0;
        justify-content: center !important;
        align-items: center !important;
        flex-wrap: wrap !important;
        width: 100%;

        @media (min-width: 768px) and (max-width: 991px) {
            width: 34%;
            justify-content: flex-end !important;
        }

        @media (min-width: 992px) {
            width: 20%;
            justify-content: flex-end !important;
        }

        @media (max-width: 767px) {
            order: 2;
            flex-direction: column;
        }

        a {
            font-size: 0.938rem;
            line-height: 1.063rem;
            font-weight: $font-regular;
            color: $darkBlue;
        }

        li {
            flex-basis: 100%;
            text-align: left;


            @media (min-width: 768px) {
                flex-basis: 45%;

            }

            @media (min-width: 992px) {
                flex-basis: 48%;
                text-align: right;
            }
        }
    }
}