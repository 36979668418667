@import "variables";

.letterBuilderPage {

    &.bg-cream {
        &>div {
            padding: 2rem;
        }
    }

    @media (min-width: 768px) {
        margin: 1.75rem 1.5rem 2.125rem 2.375rem !important;
    }

    .container {
        padding: 1rem 1.156rem;
        width: 100% !important;
        max-width: 100% !important;

        @media (min-width: 768px) {

            padding: 2rem 1.938rem;
            border-radius: 0.313rem;
        }

        .text-normal {
            width: 100% !important;
        }

        h3:not(.information-title, .title, .address, .letterEditor-viewer h3) {
            border-bottom: 0.313rem solid $green;
            margin-top: 2rem;
            padding-bottom: 0.5rem;

            @media(min-width: 768px) {
                border: none;
            }
        }

        //  .information{
        //     // border-color: $blueLight;
        //     // border-width: 0.063rem;
        //     // border-radius: 0.313rem;
        //     border: 0.063rem solid $blueLight;
        //     border-radius: 0.313rem;
        //     background-color: $blueLight !important;
        //     overflow: hidden;
        //     @media screen and (min-width: 768px){
        //         background-color: $white !important;
        //     }
        //         h3.information-title{
        //             border-bottom: none !important;
        //             border-color: $blueLight;
        //             border-bottom-width: 0.063rem !important;
        //             background-color: $blueLight !important;
        //             padding: 0.75rem 1.25rem 0.75rem 3rem !important;
        //             color: $white !important;
        //             position: relative;
        //             &::before{
        //                 content: "";
        //                 width: 1rem;
        //                 height: 1rem;
        //                 background: url('../../images/svg/information.svg') no-repeat center/cover;
        //                 display: inline-block;
        //                 position: absolute;
        //                 top: 50%;
        //                 left: 1rem;
        //                 transform: translateY(-50%);

        //             }
        //         }


        //     .description{
        //         font-size: 1rem;
        //         font-weight: 400;
        //         font-family: 'Helvetica';
        //         line-height: 1.5rem;
        //         padding: 0.15rem 1.5rem 0.25rem 1.25rem !important;
        //         margin-right: 1.25rem !important;
        //         background-color: $blueLight !important;
        //         color: $white !important;
        //         @media screen and (min-width: 768px){
        //             font-size: 1.063rem;
        //             line-height: 1.5rem;
        //             background-color: $white !important;
        //             color: $darkGrey !important;
        //         }
        //     }


        //  }
        .letterEditor-container {
            margin-top: 2.25rem;

            .subTextStyle {
                margin-bottom: unset !important;
            }

            .letterEditor-content {
                max-width: 100% !important;

                @media screen and (min-width: 768px) {
                    max-width: 50% !important;
                    width: 50% !important;
                }

                .inputFieldView {
                    width: 100% !important;
                    border: 0.094rem solid $black;
                    padding: 0.5rem 0.813rem;

                    @media (min-width: 768px) {
                        width: 80% !important;
                    }
                }

                .textStyle {
                    padding-top: 2.375rem;
                }

                .react-datepicker-wrapper {
                    width: 100%;
                    display: block;

                    input {
                        width: 100%;
                        padding: 0.5rem 0.813rem;
                        background-color: $white;
                        border: 0.094rem solid $black;

                        @media (min-width: 768px) {
                            width: 80%;
                        }
                    }
                }

            }

            .letterEditor-viewer {
                max-width: 0% !important;
                background-color: $offWhite;

                @media screen and (min-width: 768px) {
                    max-width: 50% !important;
                    width: 50% !important;
                    padding: 1.5rem 1.5rem;
                }

                h3.highlight {
                    margin: 0rem 0.313rem 0.3rem !important;
                    padding: 0rem 0.5rem !important;

                    @media screen and (min-width: 768px) {
                        margin: 0rem 0.313rem 0.3rem !important;
                        padding: 0rem 0.5rem !important;
                        border-bottom: 0.125rem solid $green !important;
                    }
                }

                h3 {
                    // text-align: right;
                }

            }

            .bottomContiner {
                p.subTextStyle {
                    margin-top: 2rem;
                }

                .editor-description {
                    p {
                        margin-bottom: 0.5rem;
                    }

                    ul {
                        list-style-type: disc;
                        padding-left: 1rem !important;

                        p {
                            margin-bottom: 0.5rem;
                        }

                    }
                }
            }
        }

        .buttonContainer {
            margin: 2rem 0rem;
            height: 3.688rem;

            .discardButton {
                text-align: center;
                background-color: $white;
                padding: 0rem 2.3rem !important;
                color: $fadeBlack;
                border: 0.125rem solid $greyLight !important;
                font-weight: 700;

                @media (max-width: 767px) {
                    padding: 0rem 2rem !important;
                }
            }

            .generateButton {
                text-align: center;
                background-color: $green !important;
                padding: 0rem 1.625rem !important;
                border: none !important;
                color: $fadeBlack;
                font-weight: 700;


                @media (max-width: 767px) {
                    margin-left: 1rem;
                    padding: 0 1rem;
                }

                img {

                    @media (max-width: 767px) {
                        width: 1.8rem !important;
                        margin-left: 1rem !important;

                    }
                }
            }
        }
    }

    .homelessness {
        padding: 2.438rem 0 2.5rem !important;

        .container {
            padding: 0 2.5rem;
        }

        @media (max-width: 768px) {
            padding: 2rem 0 2rem !important;
        }
    }

    .address {

        &:after {
            content: "\a";
            white-space: pre-wrap;
        }
    }

    .ck.ck-editor {
        margin-top: 1.5rem !important;
        border: 0.094rem solid $black !important;
    }


    .error-container {
        p {
            font-weight: 700;
        }
    }

    .letter-description-container {
        p {
            margin-bottom: 0 !important;
        }
    }
}