.email-modal {

    .modal-content {
        border-radius: 0.313rem !important;
        overflow: hidden;
    }

    .modal-dialog {
        max-width: 100%;


        @media (min-width: 992px) {
            max-width: 58%;
        }

        @media (min-width: 768px) and (max-width: 992px) {
            max-width: 75%;
        }

    }

    .modal-header {
        background-color: $blueLight;
        color: $white;
        padding: 1.313rem 2.625rem;
        align-items: center !important;
        border-radius: 0;

        @media (max-width: 767px) {
            align-items: flex-start !important;
        }

        .btn-close {
            width: 0.75rem;
            height: 0.75rem;
            padding: 0.2rem !important;
            background: url("../../images/warningOpen.svg") no-repeat center/100%;
            opacity: 1;

            @media (max-width: 767px) {
                margin-top: 0.5rem;
            }
        }
    }

    .modal-body {

        background-color: $white;

        ul {
            list-style-type: disc !important;
            padding-left: 1rem;

            li {
                p {
                    margin-bottom: 1rem;
                    font-size: 1.125rem;
                    line-height: 1.5rem;
                    font-weight: 400;
                    font-family: "Helvetica";
                }
            }
        }

        form {
            max-width: 100%;

            @media (min-width: 768px) {
                max-width: 70%;
            }

            .email-info {
                font-size: 0.875rem;
                line-height: 1.5rem;
                font-weight: 400;
                font-family: "Helvetica";
                margin-bottom: 1rem;
            }
        }


        input {
            box-shadow: none;
            outline: none;
            padding: 0.75rem 1.125rem;
            border-radius: 0.125rem;
            border: 0.063rem solid $darkGrey;
            background-color: $white;
        }



        ::placeholder {
            color: $greyLight;
            opacity: 1;
            /* Firefox */
        }

        ::-ms-input-placeholder {
            /* Edge 12-18 */
            color: $greyLight;
        }

        button {

            background-color: $green;
            border: 0.063rem solid $green;
            color: $heroBlue;
            font-weight: bold;
            padding: 0.906rem 1.125rem;
            text-align-last: left;

            @media (min-width: 768px) {
                min-width: 11.75rem;
            }

            &.button {
                &::after {
                    margin-left: 5rem;

                    @media (min-width: 992px) {
                        margin-left: 6rem;
                    }

                    @media (min-width: 768px) and (max-width: 992px) {
                        margin-left: 4.6rem;
                    }
                }
            }

            &.submit {
                font-size: 1rem;

                @media (max-width: 767px) {
                    padding-right: 4rem;
                }

                &:hover {
                    background-color: $green !important;
                }
            }

            &.cancel {
                background-color: $lightBg;
                color: $black;
                border-color: $lightBg;
                padding: 0.85rem 1.125rem;
            }

            &:hover {
                background-color: $lightBg !important;
            }
        }

        .thanks-title {
            font-size: 1.125rem;
            font-weight: 700;
            font-family: "Helvetica";
            line-height: 1.5rem;
        }

        .thanks-description {
            font-size: 1.125rem;
            font-weight: 400;
            font-family: "Helvetica";
            line-height: 1.5rem;
            margin: 2rem 0;

        }
    }


}