.hero {
    width: 100%;
    padding: 1.125rem 0 1.3rem;
    position: relative;

    @media (min-width: 992px) {
        background: url('../../images/hero.svg') no-repeat $heroBlue;
        background-position: right bottom;
        background-size: 32%;
    }
   

    @media (min-width: 768px) and (max-width: 992px) {
        background: url('../../images/hero.svg')  no-repeat  $heroBlue;
        background-position: right bottom;
        background-size: 46%;
    }

    @media (min-width: 768px) {
        min-height: 25.188rem;
        padding: 3.125rem 0 2.125rem;

    }

    // &::after {
    //         display: none;
    //         content: "";
    //         width: 50%;
    //         height: 100%;
    //         background: url('../../images/hero.svg') no-repeat 100% / 57.5%;
    //         position: absolute;
    //         right: 0;
    //         top: 2.3rem;

    //         @media (min-width: 768px)  and (max-width: 992px) {
    //             display: inline-block;
    //             background: url('../../images/hero.svg') no-repeat 100% / contain !important;
    //             top: 3.4em;
    //         }

    //         @media (min-width: 993px) {
    //             display: inline-block;
    //             background: url('../../images/hero.svg') no-repeat 100% / 57.5%;
    //             top: 2.3rem;
    //         }

        
    // }

    .title {
        @media (min-width: 768px) {
            margin-bottom: 1.438rem;
            font-size: 2rem;
            line-height: 2.313rem;
        }
    }

    .hero-content-container {
        width: 50%;
    }

    .description {
        margin: 0.83rem 0 1.375rem;
    }

    .sub-description {
        color: $fadeWhite;
        font-size: 1rem;
        font-family: 'Helvetica';
        font-weight: $font-regular;
        line-height: 1.5rem;
        margin-top: 2rem;
        white-space: nowrap;
        margin-bottom: 0;

        @media (min-width: 768px) {
            margin-top: 2.7rem;
        }

        a {
            color: $fadeWhite;
            font-size: 1rem;
            font-family: 'Helvetica';
            font-weight: $font-regular;
            line-height: 1.5rem;
            display: inline-block;
            // border-bottom: 0.125rem solid $green;
            // font-weight: bold;

        }
        
    }

   
}