.start-questions {

    width: 100%;

    .homelessness {

        padding-bottom: 0 !important;

        @media (max-width: 767px) {
            margin-top: 0;
        }

        .container {
            padding-left: 0;

            @media (min-width: 767px) and (max-width: 991px) {
                padding-left: 0.5rem;
            }

        }
    }

    .container {
        @media (max-width: 767px) {
            width: 100%;
            max-width: 100% !important;
            margin-left: 0;
            margin-right: 0;
            padding-left: 0;
            padding-right: 0;
        }

        .breadcrumb {
            @media (max-width: 767px) {
                width: 92.42%;
                margin-left: auto;
                margin-right: auto;
                padding-left: 0rem;
                padding-right: 0rem;
            }
        }

        .content-wrapper {
            @media (max-width: 767px) {
                background-color: $white;
                padding-left: 1rem;
                padding-right: 1rem;
                padding-bottom: 2.5rem;
            }

            .error-container {
                // @media (max-width: 767px) {
                //     // margin-left: 0.5rem;
                //     // margin-right: 0.5rem;
                // }
            }
        }
    }

    .button-container {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;

        @media (min-width: 768px) {
            padding-left: 1.5rem;
            justify-content: flex-start;
            margin-top: 0;
        }

        .next {

            padding: 1rem !important;
            padding-right: 5rem !important;
            background-color: $green !important;
            position: relative;

            @media (min-width: 768px) {
                height: 3.3rem;
            }

            &::after {
                position: absolute;
                top: 50%;
                right: 10%;
                transform: translate(-10%, -50%);

                // @media (max-width: 767px) {
                //     width: 2.5rem;
                //     height: 1.063rem;
                //     margin-left: 2.5rem;
                //     background: url("../../images/svg/arrow-right.svg") no-repeat center/cover;
                // }
            }

            &:hover {
                color: $heroBlue !important;
                background-color: $green !important;
            }

            @media (min-width: 768px) {
                background-color: $green !important;
                border: 0.125rem solid transparent !important;
                border-radius: 0.313rem !important;
            }

            @media (max-width: 767px) {
                border: 0.125rem solid transparent !important;
                border-radius: 0.313rem !important;
            }

            &:disabled {
                background-color: $greyLight !important;

                &::after {
                    background: url("../../images/arrow-black.svg") no-repeat center/cover !important;
                    opacity: 0.4;
                    position: absolute;
                    top: 50%;
                    right: 10%;
                    transform: translate(-10%, -50%) rotate(180deg);
                }
            }


        }

        .button:disabled {
            background-color: $lightestGery;
            opacity: 0.8;
            color: $btndisabled !important;
        }

        .prev {
            margin-right: 0.8rem;
            border: 0.125rem solid transparent !important;
            border-radius: 0.313rem !important;
            width: auto;
            background: $lightBg;
            padding: 1rem !important;
            position: relative;
            width: 3.313rem;

            &:disabled {
                background: $lightBg;
                opacity: 0.5;
            }

            @media (min-width: 768px) {
                width: 3.313rem;
                height: 3.3rem;
                background-color: $lightBg;
                border: 0.125rem solid transparent !important;
                border-radius: 0.313rem !important;
            }

            &::after {
                margin-left: 0;
                background: url("../../images/arrow-black.svg") no-repeat center/cover !important;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .card-container {
        li {
            border-color: $red !important;

            a {
                color: $red !important;
            }
        }
    }

    .notice-card,
    .info-asking {

        @media (max-width: 767px) {
            margin-left: 1rem;
            margin-right: 1rem;
        }

    }

    .skip {

        position: relative;

        @media (min-width: 768px) {
            height: 3.125rem;
        }


        &::after {
            background: url('../../images/arrow-black.svg') no-repeat center/cover;
            position: absolute;
            top: 50%;
            right: 10%;
            transform: translate(-10%, -50%) rotate(180deg);
        }

        &.next {
            background-color: $lightBg !important;
            padding-right: 5rem !important;

            &::after {
                transform: translate(-10%, -50%) rotate(180deg);
            }

        }

        color: $black;

        &.next:hover {
            color: $black !important;
            background-color: $lightBg !important;
        }
    }

    .notice-card {
        .description {
            border-radius: 0 0 0.313rem 0.313rem;
        }
    }

    .start-questions.bg-white {
        border-radius: 0 0 0.625rem 0.625rem;
    }
}