.breadcrumb-container {
    width: 100%;

    @media (min-width: 768px) {
        width: 71%;

    }
}

.breadcrumb {

    margin-top: 1.5rem;
    margin-bottom: 0.5rem;

    a {
        font-size: 1rem;
        line-height: 1.5rem;
        font-family: 'helvetica';
        font-weight: 700;
        color: $darkBlue;

        &.home {
            position: relative;
            &::after {
                content: "";
                width: 1rem;
                height: 1rem;
                display: inline-block;
                background: url("../../images/breadcrumb.svg") no-repeat center/cover;
                position: absolute;
                right: 15%;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    span {
        @extend a;
        color: $lightGrey;
    }

}