.faq {

    .faq-topic-title {
        font-size: 1.5rem;
    }

    .faq-content {
        margin-top: 2rem;
    }

    ul {
        
        li {
            flex-basis: 100%;

            &.active {
                button {
                    color: $black;
                    background-color: $green !important;
                    font-weight: 700 !important;
                    border: 0.063rem solid $borderFaq;
                }       
            }

            button {
                width: 100%;
                padding: 0.875rem;
                border: 0.063rem solid $borderFaq;
                box-shadow: none;
                text-align: left;
                border-radius: 0.313rem;
                font-weight: 400;
                transition: background-color, font-weight  0.3s linear;

                
               

                &:hover {
                    color: $black;
                    background-color: $green !important;
                    font-weight: 700 !important;
                    border: 0.063rem solid $borderFaq;
                }
            }
        }
    }

    .accordion-list-container {
        
        background-color: $lightBg !important;
        max-width: 100%;
            width:  100%;

        @media (min-width: 768px) {
            background-color: white !important;
            max-width: 72.5%;
            width:  72.5%;
        }
        




        .accordion-container {
            background-color: transparent;
        }

        .accordion-item {
            border-radius: 0.313rem;
            border-width: 0.125rem;

            &:has(> div.show) {
                border-width: 0.125rem;

                @media (min-width: 768px) {
                    border-width: 0.125rem;
                }
                
            }

            @media (min-width: 768px) {
                // border-radius: 1.25rem;
                border-width: 0.125rem;
            }

            .accordion-header {
                button {

                    @media (max-width: 767px) {
                        display: flex;
                        align-items: baseline;
                    }
                    

                    &::after {
                        @media (max-width: 767px) {
                            width: 0.8rem;
                            height: 0.5rem;
                        }
                        
                    }
                }
            }
        }
    }
}