.info-asking {


    @media (min-width: 768px) {
        width: 71% !important;
        max-width: 71% !important;
    }


    background-color: $infoTitle;
    border-radius: 0.313rem;
    overflow: hidden;
    // width: 92.42%;
    margin-left: 1.5rem;
    margin-right: 1.5rem;

    @media (min-width: 768px) {
        margin-left: 0;
        margin-right: 0;
    }
    


    h2 {
        background-color: $infoTitle;
        color: $white;
        padding: 0.625rem 1rem;
        margin-bottom: 0;

        &::before {
            content: "";
            display: inline-block;
            width: 1.063rem;
            height: 1.063rem;
            background: url('../../images/svg/information.svg') no-repeat center/cover;
            vertical-align: middle;
            margin-right: 1rem;
        }
    }

     p {
        padding: 0 1rem 1rem;
        color: $white;
        line-height: 1.286rem;
        margin-bottom: 0;


        @media (min-width: 768px) {
            padding: 1rem;
            background-color: $white;
            color: $grey
        }
     }
}