.card-container {


    @media (min-width: 768px) and (max-width: 992px) {
        .cards {
            flex-basis: 25% !important;
        }
    }

    .cards {
        padding: 1rem;
        border-radius: 0 0.313rem 0.313rem 0;
        flex-basis: 48.5% !important;

       
    
        p {
            flex: 1;
        }

        @media (min-width: 768px) and (max-width: 992px) {
            flex-basis: 28% !important;
            margin-right: 1.5rem;
    }
    
        @media (min-width: 993px) {
            flex-basis: 20% !important;
            margin-right: 2rem;
        }
    
        &.green-border {
            border-left: 0.25rem solid $green;
        }

        .select {
            
            &:after {
                content: '';
                width: 0.63rem;
                height: 1rem;
                display: inline-block;
                background: url('../../images/Path.svg') no-repeat center/cover;
                float: right;
            }
        }
    }

    .case {
        p {
            line-height: 1.625rem;
            font-weight: 400;
        }
    }
}

