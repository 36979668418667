@import "variables";
.accordion-container {

    background-color: $white;

    .accordion-item {

        margin-bottom: 1.5rem;
        border: 0.188rem solid $borderLight;
        // border-radius: 1.25rem;
        overflow: auto;

        @media (min-width: 768px) {
            border-radius: 0.313rem;
        }

        &:has(> div.show) {
            border: 0.188rem solid $green;
        }

        button {

            font-size: 1rem;
            font-weight: 700;
            line-height: 1.5rem;
            color: $_darkGrey;
            background-color: $white !important;
            box-shadow: none !important;
            border: none;

            @media (min-width: 768px) {
                padding: 1.5rem;
                font-size: 1.125rem;
                line-height: 1.25rem;
            }
            
            &::after {
                
                width: 1.092rem;
                height: 0.707rem;
                background: url('../../images/accordion-arrow.svg') no-repeat center/cover;
                
                @media (max-width: 767px) {
                    margin-left: 0.5rem;
                }
            }

        }


    
        .accordion-body {
            padding-top: 0;
            background-color: $white;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            font-family: 'helvetica';
            color: $_darkGrey;
        }
    }   

    
}