// Body
$body-bg: #F3F2F1;

// Typography
$font-family-sans-serif: 'Open Sans', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$white: #fff;
$blue: #3490dc;
$offWhite: #fafafa;
$blueLight: #1D70B8;
$darkBlue: #003078;
$heroBlue: #001B9C;
// $indigo: #6574cd;
// $purple: #9561e2;
// $pink: #f66d9b;
$red: #D4351C;
// $orange: #f6993f;
// $yellow: #ffed4a;
$green: #00DD97;
$grey: #505A5F;
$jet: #353535;
$greyLight: #D3D3D3;
$lightGrey: #3B3B3B;
$lightestGery: #bcbcbc;
$darkGrey: #0B0C0C;
$_darkGrey: #0B0B0B;
$fadeBlack: #0B0B0B;
$lightBg: #F3F2F1;
$borderLight: #E7E6E9;
$borderFaq: #d9d9d9;
$infoTitle: #989898;
$btndisabled: #847F8F;
$fadeWhite: #cacaca;
$inpoutFadeColor: #282828;
$actionBorder: #D1d1d1;
// $teal: #4dc0b5;
// $cyan: #6cb2eb;

$font-bold: bold;
$font-semiBold: 700;
$font-regular: 400;