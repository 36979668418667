.homelessness {

    @media (max-width: 767px) {
        padding: 2.438rem 0 2.938rem !important;
    }

    .title {
        @media (min-width: 768px) {
            margin-bottom: 1.188rem;
        }
    }

    .card-container {
        .cards {
            &:not(:nth-child(n+1):nth-child(-n+4)) {
                margin-top: 2rem !important;
            }
        }
    }

    ul {
        li {
            h4 {
                flex-grow: 1;
            }
        }
    }
}