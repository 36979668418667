@import "variables";

header {
    background-color: $white !important;
    border-bottom: 0.125rem solid $darkBlue;

    @media (min-width: 768px) {
        border-bottom: none;
    }

    .navbar {
        background-color: $white !important;
        padding: 1.5rem 0 1.5rem;
    }

    .container {
        @media (max-width: 767px) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .navbar-toggler {
        border: 0 !important;
        padding: 0 !important;
        width: 2.75rem;
        height: 2.625rem;
        box-shadow: none !important;
        outline: none !important;


        .navbar-toggler-icon {
            width: 2.5rem;
            height: 2.9rem;
            display: inline-block;
            background: url('../../images/Menu-Icon.svg') no-repeat center/100% !important;
        }
    }


    .navbar-brand {
        width: 7.688rem;

        img {
            max-width: 7.625rem !important;
        }
    }

    .nav-link {
        font-size: 1.063rem;
        line-height: 1.188rem;
        color: $darkBlue !important;
        font-weight: $font-semiBold;
        position: relative;
        display: inline-block;
        padding-left: 0 !important;
        padding-right: 0 !important;
        width: fit-content;

        &:not(:last-of-type) {
            margin-right: 2rem;
        }

        &:last-of-type {
            color: $red  !important;
            border-bottom: none !important;

            &::after {
                display: none !important;
            }
        }

        &::after {
            content: "";
            width: 100%;
            height: 0.188rem;
            display: inline-block;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: $green;
            position: absolute;
            border-radius: 1rem;
            transition: 0.3s ease-in-out;
            opacity: 0;

            @media (max-width: 992px) {
                width: 100%;
                left: 0;
                transform: translateX(0%);
            }

            @media (max-width: 767px) {
                width: 100%;
            }

        }

        &:not(.navbar-brand):hover {
            &::after {
                opacity: 1;
            }
        }

        &.active {
            &::after {
                opacity: 1;
            }
        }
    }

}