@import 'variables';

html,
body {

    font-size: 3.8vw;
    font-family: 'Helvetica';
    min-height: 100vh;



    @media (min-width: 768px) and (max-width: 992px) {
        font-size: 15px;
    }

    @media (min-width: 992px) {
        font-size: 15px;
    }

    // @media (min-width: 1050px) {
    //     font-size: 11.25px;
    // }

    // @media (min-width: 1200px) {
    //     font-size: 13px;
    // }

    // @media (min-width: 1400px) {
    //     font-size: 15px;
    // }

    @media (min-width: 1280px) {
        font-size: 16px;
    }


}

#app {

    min-height: 100vh;

    main {
        flex-grow: 1;
    }
}

a {
    text-decoration: none;
    display: inline-block;
}

ul {
    list-style-type: none;
    padding-left: 0 !important;
}

img {
    width: 100%;
}

.inter-font {
    font-family: 'inter' !important;
}

strong {
    font-family: 'inter-bold' !important;
}

@font-face {
    font-family: 'inter-regular';
    src: url('../../fonts/Inter-Regular.ttf');
}

@font-face {
    font-family: 'inter-bold';
    src: url('../../fonts/Inter-SemiBold.ttf');
}


.container {
    // max-width: 73.938rem;
    max-width: 92.42% !important;
    width: 92.42%;
    margin: 0 auto;
}

.button {
    background-color: $green;
    color: $darkBlue;
    padding: 1rem 2rem;
    font-size: 1.125rem;
    line-height: 1.25rem;
    font-weight: 700;
    font-family: 'Helvetica';
    border: none;
    border-radius: 0.313rem;
    position: relative;



    &.without-arrow {
        padding-top: 0.563rem;
        padding-bottom: 0.563rem;
        font-size: 1rem;
    }

    @media (max-width: 767px) {
        padding: 1rem 1.5rem;
    }

    &:not(.without-arrow) {
        padding-right: 4rem;

        &::after {
            content: "";
            display: inline-block;
            width: 0.625rem;
            height: 1.092rem;
            background: url("../../images/Path.svg") no-repeat center/cover;
            position: absolute;
            top: 50%;
            right: 10%;
            transform: translate(-10%, -50%);

        }
    }


}



.white {
    color: $white !important;
}

.darkBlue {
    color: $darkBlue;
}

.lightBlue {
    color: $heroBlue !important;
}

.green {
    color: $green;
}

.lightGrey {
    color: $lightGrey;
}

.bg-white {
    background-color: $white !important;
}

.bg-cream {
    background-color: $lightBg;
}

.bg-red {
    background-color: $red;
}

.darkGrey {
    color: $darkGrey;
}

.textBlue {
    color: $heroBlue;
}

.black {
    color: $black;
}

.grey {
    color: $grey;
}

.title {
    font-size: 1.313rem;
    font-weight: 700;
    font-family: Helvetica;
    line-height: 2rem;

    @media (min-width: 767px) {
        font-size: 1.438rem;
        line-height: 1.653rem;
    }
}

.title-26 {
    font-size: 1.625rem;
    font-weight: 700;
    font-family: 'Helvetica';
    line-height: 1.969rem;

    @media (min-width: 767px) {
        font-size: 2rem;
        line-height: 2.406rem;
    }
}

.title-32 {

    font-size: 1.625rem;
    line-height: 1.969rem;
    font-weight: 700;
    font-family: 'Helvetica';

    @media (min-width: 768px) {
        font-size: 2rem;
        line-height: 2.3rem;
    }

}

.text-tittle-19 {
    font-size: 1rem;
    font-weight: 700;
    font-family: 'Helvetica';
    line-height: 1.5rem;
    color: $darkGrey;

    @media (min-width: 767px) {
        font-size: 1.188rem;
        line-height: 1.36rem;
    }

}

.text-tittle-19-normal {
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Helvetica';
    line-height: 1.5rem;
    color: $darkGrey;

    @media (min-width: 767px) {
        font-size: 1.188rem;
        line-height: 1.36rem;
    }

}

.text-tittle-18 {
    font-size: 1rem;
    font-weight: 700;
    font-family: 'Helvetica';
    line-height: 1.5rem;
    color: $darkGrey;

    @media (min-width: 767px) {
        font-size: 1.125rem;
        line-height: 1.5rem;
    }

}

.text-14 {
    font-size: 1rem;
    font-weight: 700;
    font-family: 'Helvetica';
    line-height: 1.5rem;
    color: $darkGrey;

    @media (min-width: 767px) {
        font-size: 0.875rem;
        line-height: 1.313rem;
    }
}

.text-14-normal {
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Helvetica';
    line-height: 1.5rem;
    color: $darkGrey;

    @media (min-width: 767px) {
        font-size: 0.875rem;
        line-height: 1.313rem;
    }
}

//line height 26px
.text-14-26 {
    font-size: 1rem;
    font-weight: 700;
    font-family: 'Helvetica';
    line-height: 1.5rem;
    color: $darkGrey;

    @media (min-width: 767px) {
        font-size: 0.875rem;
        line-height: 1.625rem;
    }
}

.text-17 {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    font-family: 'inter';

    @media (min-width: 768px) {
        font-size: 1.063rem;
        line-height: 1.286rem;
    }
}

.text-15 {
    font-size: 1rem;
    font-weight: 600;
    font-family: 'Helvetica';
    line-height: 1.5rem;
    color: $darkGrey;

    @media (min-width: 767px) {
        font-size: 0.938rem;
        line-height: 1.134rem;
    }
}

.text-16 {
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Helvetica';
    line-height: 1.5rem !important;
    color: $darkGrey;

    @media (min-width: 767px) {
        font-size: 1rem !important;
        line-height: 1.5rem !important;
    }
}

.text-16-bold {
    font-size: 1rem;
    font-weight: 700;
    font-family: 'Helvetica';
    line-height: 1.15rem !important;
    color: $darkGrey;

    @media (min-width: 767px) {
        font-size: 1rem !important;
        line-height: 1.15rem !important;
    }
}

.text-18 {
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Helvetica';
    line-height: 1.5rem;
    color: $darkGrey;

    @media (min-width: 767px) {
        font-size: 1.125rem;
        line-height: 1.281rem;
    }
}

.text-20 {
    font-size: 1.125rem;
    font-weight: 700;
    font-family: 'Helvetica';
    line-height: 1.294rem;
    color: $darkGrey;

    @media (min-width: 767px) {
        font-size: 1.25rem;
        line-height: 1.438rem;
    }
}


.text-normal {
    font-size: 1rem;
    font-weight: $font-regular;
    line-height: 1.5rem;
    font-family: 'Helvetica';

    @media (min-width: 768px) {
        font-size: 1.188rem;
        line-height: 1.75rem;
    }
}

.text-title-24 {
    font-size: 1.5rem;
    line-height: 1.969rem;
    font-weight: 700;
    font-family: 'Helvetica';

    @media (min-width: 768px) {
        font-size: 2rem;

    }
}

.text-24 {
    font-size: 1.313rem;
    line-height: 1.969rem;
    font-family: 'Helvetica';
    font-weight: 700;
    color: $darkGrey;

    @media (min-width: 768px) {
        font-size: 1.5rem;
        line-height: 1.725rem;
    }

}

.text-title-21 {
    font-size: 1.313rem;
    line-height: 1.969rem;
    font-weight: 700;
    font-family: 'Helvetica';

    @media (min-width: 768px) {
        font-size: 2rem;

    }
}

.text-title-26 {
    font-size: 1.625rem;
    line-height: 1.969rem;
    font-family: 'helvetica';
    font-weight: 700;
}

.text-title-28 {
    font-size: 1.75rem;
    line-height: 1.969rem;
    font-family: 'helvetica';
    font-weight: 700;
}

.text-sub-title {
    font-family: 'Helvetica';
    font-size: 1.125rem;
    font-weight: $font-semiBold;
    line-height: 1.25rem;
}

.text-sub-title-16 {
    font-family: 'Helvetica';
    font-size: 1rem;
    font-weight: $font-semiBold;
    line-height: 1.5rem;
}

.text-sub-title-20 {
    font-size: 1.25rem;
    line-height: 1.438rem;
    font-weight: 700;
    font-family: 'Helvetica';
}

.text-21 {
    font-size: 1.313rem;
    line-height: 1.969rem;
    font-weight: 700;
    font-family: 'Helvetica';

    @media (min-width: 768px) {
        font-size: 1.313rem;
        line-height: 1.509rem;
    }
}

.red {
    color: $red;
}

.bg-green {
    background-color: $green;
}

.bg-light {
    background-color: $lightBg !important;

    .container {
        &>p {

            @media (min-width: 768px) {
                width: 50%;

            }
        }
    }

    &:not(#dropdown-container) {
        padding: 3.438rem 0 3.938rem;

    }
}

.bg-cream {
    background-color: $lightBg !important;
}

.bg-blue {
    background-color: $heroBlue;
}

.fadeWhite {
    color: $fadeWhite !important;
}

#dropdown-container {

    button {
        box-shadow: none;
        outline: none;
        border: none;
        border-bottom: 0.125rem solid $green;
        padding: 0;
        margin-left: 0.5rem;
        background-color: transparent !important;
        color: $darkBlue !important;
        padding-right: 1.5rem;
        position: relative;
        border-radius: 0;

        &:hover {
            color: $darkBlue;
        }

        &.lightBlue {
            color: $heroBlue !important;

            @media (max-width: 767px) {
                max-width: 96.125%;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

        }

        &::after {
            width: 0.5rem;
            height: 0.8rem;
            border: none;
            background: url('../../images/Path.svg') no-repeat center/cover;
            position: absolute;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
            right: 0.125rem;
        }
    }

    #housing {
        border: none !important;
        background: #FFFFFFF2;
        border: 0.063rem solid #F3F2F1 !important;
        box-shadow: 0px 0.125rem 0.25rem 0px #D6D6D633;


        li {
            padding: 0rem;
            cursor: pointer;

            &:hover {
                background-color: $lightBg !important;
            }


            a {
                padding: 1.125rem 0.85rem 0.5rem;
                border-bottom: none;

                display: inline-block;


                &:hover {
                    background-color: $lightBg !important;
                    color: $darkBlue;
                }



            }
        }
    }
}

.backActionButton {
    font-family: 'Helvetica';
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    background-color: $white;
    border: 0.125rem solid $greyLight;
    color: $black;

    @media (min-width: 768px) {
        font-size: 1.313rem;
        line-height: 1.509rem;
        padding-left: 1.5rem;
        padding-right: 2.5rem;

    }

    .leftArrowStyle {
        height: 2.563rem;
        width: 2.563rem;
        margin-right: 1.5rem;
        vertical-align: 'middle';

        @media (max-width: 767px) {
            margin-left: 0.8rem;
        }
    }
}

.accordion-item:last-of-type {
    margin-bottom: 0 !important;
}

.ck-editor__editable {
    min-height: 9.375rem !important;
}

.smallButton {
    text-align: center;
    margin: 0 0.5rem;
    height: 2.438rem;
    background-color: $green !important;
    padding: 0.625rem !important;
    border: none !important;
    border-radius: 0.125rem !important;
    color: $fadeBlack !important;

    &:last-of-type {
        margin-right: 0;
    }

    @media (max-width: 767px) {
        line-height: 1rem;
        font-weight: 700;
    }
}

// .information-content {
//     .hide-button {
//         padding: 0.5rem 1rem;
//         color: $white;
//         background-color: transparent !important;
//         border: 0.125rem solid $white !important;
//         border-radius: 0;

//         &.show {
//             &::after {
//                 transform: rotate(180deg);
//             }
//         }

//         &::after {
//             content: "";
//             width: 0.728rem;
//             height: 0.461rem;
//             display: inline-block;
//             margin-left: 1rem;
//             vertical-align: middle;
//             background: url("../../images/down-arrow-white.svg") no-repeat center/cover;
//         }
//     }
// }

.listStyleMsg {
    list-style-type: disc;
    padding-left: 2rem !important;
}

.redModal {
    background-color: $red !important;
    color: $white !important;
}

.modalTitle {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 400;
    font-family: "Helvetica";
    color: $white;

    &::before {
        content: "";
        display: inline-block;
        width: 1.2rem;
        height: 1.2rem;
        background: url("../../images/svg/information.svg") no-repeat center/cover;
        vertical-align: middle;
        margin-right: 0.953rem;
        margin-left: 0.518rem;
    }

    @media screen and (min-width: 768px) {
        &::before {

            width: 1.171rem;
            height: 1.12rem;

        }
    }
}

.modalErrorTitle {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 400;
    font-family: "Helvetica";
    color: $white;
    // margin-left: 1.518rem;
}

// inputfield error border
.inputError {
    border: 0.063rem solid $red !important;
}

// mandatory field
.mandatory {
    color: $red;
    font-size: 1rem;
    font-weight: 700;
    font-family: "Helvetica";
    line-height: 1rem;
    padding-bottom: 1rem;
}

// accordion ul li style to default
.accordion {
    ul {
        list-style-type: initial !important;
        padding-left: 2rem !important;
    }
}