.radio-dropdown {

    margin-bottom: 2rem;
    border-radius: 0.313rem;
    overflow: hidden;

    .toggle-button {
        width: 100%;
        text-align-last: left;
        background: transparent;
        border-bottom: 0;
        border-bottom: none;
        border-radius: 0;

        & + div.dropdown-menu {
            position: static !important;
            transform: translate(0) !important;
            background: transparent;
            border: none;

            .btn.show {
                border-color: $blue !important;
            }

            button {
                width: 88%;
                margin: 0 auto 1rem;
                padding: 0.875rem;
                transition: 0.3s linear;
                border: 0.063rem solid $borderFaq;
                box-shadow: none;
                text-align: left;
                border-radius: 0.313rem;
                color: $black;
                

                &.active {
                    background-color: $green;
                    color: $black;
                    font-weight: bold;

                    // &::before {
                    //     background: url('../../images/svg/radioActive.svg') no-repeat center/100%;

                    // }
                }

                // &::before {
                //     content: '';
                //     width: 1.658rem;
                //     height: 1.658rem;
                //     display: inline-block;
                //     background: url('../../images/svg/radiodisabled.svg') no-repeat center/100%;
                //     vertical-align: bottom;
                //     margin-right: 1rem;
                // }
            }
        }

        
    }

    .dropdown-menu {
        border-top: 0;
    }

    .dropdown-toggle {
        clear: both;
        position: relative;
        padding: 1rem 1.5rem;
        font-weight: 700;
        font-family: "helvetica";
        border-bottom: 0;
        border: 0;
        background-color: $white !important;
        color: $darkGrey;

        &::after {
            width: 0.8rem;
            height: 0.5rem;
            border: none !important;
            background: url("../../images/accordion-arrow.svg") no-repeat center/cover;
            vertical-align: middle;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 1.5rem;
            transition: 0.3s ease-in-out;
        }

        

        &.show {
            &::after {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }
}